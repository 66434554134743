<template>
  <v-row justify="center">

    <v-snackbar
      v-model="error.show"
    >

    <p class="py-0 my-0 font-weight-black">Ops, falhou!</p>
      {{ error.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="error.show = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="modal" persistent max-width="600px" v-if="logar()">
      <v-card>
        <v-card-title>
          <span class="headline">Por favor, insira suas credenciais</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="token"
                  label="Google Authenticator - Código"
                  :hint="versao + ' - ' + url"
                  persistent-hint
                  prepend-icon="mdi-key"
                  type="password"
                  v-on:keyup.enter="auth"
                  filled
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { autenticarApiClientes } from '../../services/api';
import { checarSessao } from '../../utils/utils';

export default {
  name: "Auth",
  data() {
    return {
      token: "",
      modal: true,
      error: {
        show: false,
        message: "",
      },
    };
  },
  beforeMount() {
    setTimeout(this.checar, 500);
  },
  methods: {
    setError(error) {
      this.modal = true;
      this.error = {
        show: true,
        message: (error ? error : "Falha não identificada, tente novamente."),
      };
    },
    /**
     *
     */
    logar() {
      return checarSessao();
    },
    checar() {
      const expirou = checarSessao();
      if (!expirou) {
        this.$router.push({ path: "/dashboard" });
      }
    },
    /**
     *
     */
    auth() {
      this.modal = false;
      autenticarApiClientes(this.token)
        .then((response) => {
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("token_exp", new Date())
          localStorage.setItem("gh_token", response.data.data.gh_token)
          /**
           *
           */
          this.$router.push({ path: "/dashboard/" });
        })
        .catch((error) => {
          this.setError(error?.response?.data?.message);
          this.token = "";
        });
    },
  },
};
</script>
