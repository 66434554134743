import axios from "axios";

const api = axios.create({
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const isDesenvolvimento = process.env.NODE_ENV === "development";
const API_PAINEL_URL = isDesenvolvimento ? "https://painel.gamadelivery.app/api-dev.php" : "api.php";

/*** API ***/

/*** API GAMADELIVERY ***/
export const loginSistema = (cliente, identificacao, token) => `https://${cliente}${identificacao}/app/authorization/?token=${token}`;
export const alterarVencimentoSistema = async (cliente, identificacao, body) => await api.post(`https://${cliente}${identificacao}/app/vencimento/`, body);
export const enviarNotificacaoSistema = async (cliente, identificacao, body) => await api.post(`https://${cliente}${identificacao}/app/notificacao/`, body);
export const alterarResponsavelSistema = async (cliente, identificacao, body) => await api.post(`https://${cliente}${identificacao}/app/responsavel/`, body);
export const alterarDadosClienteSistema = async (cliente, identificacao, body) => await api.post(`https://${cliente}${identificacao}/app/cliente/`, body);
export const alterarInformativoDoCliente = async (cliente, identificacao, body) => await api.post(`https://${cliente}${identificacao}/app/informacao/`, body);

/*** API DO CADASTRO ***/
export const cadastrarSistema = async (identificacao, body) => await api.post(`https://cadastro${identificacao}/`, body);
export const alterarVersaoSistema = async (identificacao, body) => await api.post(`https://cadastro${identificacao}/`, body);
export const removerSistema = async (identificacao, body) => await api.post(`https://cadastro${identificacao}/`, body);
export const desbloquearSistema = async (identificacao, body) => await api.post(`https://cadastro${identificacao}/`, body);

/*** API ASAAS ***/
export const asaasListarFaturas = async (token, cliente) => await api.get(`${API_PAINEL_URL}?token=${token}&faturas=${cliente}`);
export const asaasCriarFatura = async (token, fatura) => await api.get(`${API_PAINEL_URL}`, {
  params: { token: token, gerarFatura: JSON.stringify(fatura) },
});

/*** API RESPONSÁVEL ***/
export const autenticarApiClientes = async (token) => await api.post(API_PAINEL_URL, {
  token: token,
});
export const fetchApiClientes = async (token) => await api.get(`${API_PAINEL_URL}?token=${token}&clientes=true`);
export const responsavelGeralDados = async (token) => await api.get(`${API_PAINEL_URL}?token=${token}&responsavel=true`);
export const responsavelGeralSalvar = async (token, responsavel) => await api.get(`${API_PAINEL_URL}`, {
  params: { token: token, responsavelSalvar: JSON.stringify(responsavel) },
});